
.tasks {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 30px 5px;
    -webkit-box-align: center;
            align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 60px);
  }
  
  .task {
    padding: 18px 15px 15px 15px;
    margin-bottom: 15px;
    min-width: 450px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .task.-is-completed {
    background: rgba(0, 196, 121, 0.1);
  }
  .task.-is-completed .task-status {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    color: #00c479;
  }
  .task.-is-completed .task-title, .task.-is-completed .task-description {
    text-decoration: line-through;
  }
  .task.-is-completed .btn-complete-task {
    background: rgba(0, 196, 121, 0.1);
    color: #00c479;
  }
  .task.-is-removed {
    background: rgba(244, 83, 102, 0.1);
  }
  .task.-is-removed .task-status {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    color: #F45366;
  }
  .task.-is-removed .task-description {
    color: rgba(0, 0, 0, 0.2);
  }
  .task.-is-removed .btn-complete-task {
    background: transparent;
    color: #222;
  }
  .task.-is-removed .btn-remove-task {
    background: rgba(244, 83, 102, 0.1);
    color: #F45366;
  }
  @media screen and (max-width: 500px) {
    .task {
      width: 100%;
    }
  }
  .task.swipe-right {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    opacity: 0;
  }
  /* .task:hover {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.13);
  }
   */
  .task-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .left-side {
    width: calc(100% - 100px);
    height: auto;
  }
  .left-side > ion-icon {
    color: #F45366;
    margin-right: 10px;
  }
  
  .task-title {
    font-size: 1.2rem;
  }
  
  .right-side {
    width: 125px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  
  .btn-edit-task,
  .btn-complete-task,
  .btn-remove-task {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    width: 120px;
    height: 54px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .btn-edit-task {
    background: rgba(98, 125, 254, 0);
    margin-right: 10px;
  }

  .btn-complete-task {
    background: rgba(0, 196, 121, 0);
  }

  
  .btn-remove-task {
    background: rgba(244, 83, 102, 0);
    margin-left: 10px;
  }

  
  .task-body {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  
  .task-description {
    color: #999;
    line-height: 1.5;
    margin-left: 27px;
    display: inline-block;
  }
  
  .task-footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: end;
            justify-content: space-between;
    margin-top: 10px;
  }
  
  .task-status {
    margin-right: 15px;

    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -moz-user-select: none;
  }
  
  .task-timestamp {
    background: #f2f3f8;
    padding: 8px 10px;
    border-radius: 5px;
    z-index: 3;
    font-size: 0.8rem;
    display: inline-block;
    color: #666;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -moz-user-select: none;
  }
  
  @-webkit-keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    20% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
    }
    40% {
      -webkit-transform: translateX(-7px);
              transform: translateX(-7px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
  }
  
  @keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    20% {
      -webkit-transform: translateX(7px);
              transform: translateX(7px);
    }
    40% {
      -webkit-transform: translateX(-7px);
              transform: translateX(-7px);
    }
    60% {
      -webkit-transform: translateX(3px);
              transform: translateX(3px);
    }
    80% {
      -webkit-transform: translateX(-3px);
              transform: translateX(-3px);
    }
  }
  